import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { ELikeType } from "../../enums/like-type.enum";

@Injectable()
export class CommunityService {
  private apiPrefix = '/client/community-post';

  constructor(
    private api: ApiService,
  ) {
  }

  getLastPosts(queryString = '') {
    return this.api.get(`${this.apiPrefix}${queryString}`);
  }

  getPostWithId(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}`);
  }

  createLikeStatus(postId: number, likeType: ELikeType) {
    return this.api.patch(`${this.apiPrefix}/${postId}/like-dislike`, {
      likeType,
    });
  }

  deleteLikeStatus(postId: number) {
    return this.api.delete(`${this.apiPrefix}/${postId}/like-dislike`);
  }
}
