import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";
import { IGenericKeyValue } from "../../models/exam/general.model";
import { toQueryString } from "../../helpers/query-string.helper";

@Injectable()
export class CommunityCommentService {
  private apiPrefix = '/client/community-post/';

  constructor(
    private api: ApiService,
  ) {
  }

  getPriorityComment(postId: number, parentCommentId: number, subCommentId: number) {
    return this.api.get(`${this.apiPrefix}${postId}/comments/${parentCommentId}/${subCommentId}`);
  }

  getComments(postId: number, queryParams: IGenericKeyValue = {}): Observable<any> {
    let queryString = toQueryString(queryParams);
    queryString = (queryString ? `?` + queryString : '');
    return this.api.get(`${this.apiPrefix}${postId}/comments${queryString}`);
  }

  getCommentAnswers(postId: number, commentId: number, queryParams: IGenericKeyValue = {}) {
    let queryString = toQueryString(queryParams);
    queryString = (queryString ? `?` + queryString : '');
    return this.api.get(`${this.apiPrefix}${postId}/comments/${commentId}/answers${queryString}`);
  }

  likeComment(postId: number, commentId: number) {
    return this.api.patch(`${this.apiPrefix}${postId}/comments/${commentId}/like`, {});
  }

  deleteLikeComment(postId: number, commentId: number) {
    return this.api.delete(`${this.apiPrefix}${postId}/comments/${commentId}/like`);
  }

  dislikeComment(postId: number, commentId: number) {
    return this.api.patch(`${this.apiPrefix}${postId}/comments/${commentId}/dislike`, {});
  }

  deleteDislikeComment(postId: number, commentId: number) {
    return this.api.delete(`${this.apiPrefix}${postId}/comments/${commentId}/dislike`);
  }

  sendComment(postId: number, data: object) {
    return this.api.post(`${this.apiPrefix}${postId}/comments`, data);
  }

  editComment(postId: number, commentId: number, data: object) {
    return this.api.patch(`${this.apiPrefix}${postId}/comments/${commentId}`, data);
  }

  deleteComment(postId: number, commentId: number) {
    return this.api.delete(`${this.apiPrefix}${postId}/comments/${commentId}`);
  }
}
